/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Sarala:wght@400;700&display=swap");
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color to match images */
  /*Green dark 190 - Green 171 - Grren Blue 200*/


  /* HSL color mode */
  --first-color: #464850;
  --first-color-second: #ff3200;
  --gray: #111;
  --white-color: #FFF;
  --background: #eeecec;

  /*========== Font and typography ==========*/
  --body-font: 'Montserrat', sans-serif;
  --title-font: 'Sarala', sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 2.25rem;
  --h3-font-size: 1.8rem;
  --h4-font-size: 1.2rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-light: 300;
  --font-medium: 400;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Hover overlay ==========*/
  --transition: .3s;
  --img-hidden: hidden;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 4.2rem;
    --h1-font-size: 3.4rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.2rem;
    --h4-font-size: 1rem;
    --normal-font-size: .875rem;
    --small-font-size: .75rem;
    --smaller-font-size: .613rem;
  }
}

@media screen and (max-width: 600px) {
    :root {
        --biggest-font-size: 2rem;
        --h1-font-size: 1.6rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --h4-font-size: .95rem;
        --normal-font-size: .875rem;
        --small-font-size: .75rem;
        --smaller-font-size: .613rem;
      }
}

body{
    background-color: var(--first-color) !important;
    margin: 0;
    padding: 0;
}

label, input, textarea, button, p, select {
    font-family: var(--body-font);
    font-weight: 400 !important;
    font-size: 1rem !important;
}

.nav-link{
    font-family: var(--title-font) !important;
    font-weight: var(--font-light) !important;
    font-size: var(--h4-font-size) !important;
}

.login{
    margin: 10% 0 !important;
}

.navbar-brand{
    color: var(--first-color-second) !important;
}

.navbar-brand strong{
    font-weight: var(--font-semi-bold);
}

.title__h1{
    font-size: var(--h1-font-size);
    color: var(--first-color-second);
}

.title__h2{
    font-size: var(--h2-font-size);
    color: var(--first-color-second);
}

.btn-block{
    width: 95% !important;
}

.navbar-collapse{
    flex-grow: 0 !important;
}

.card__content{
    background-color: var(--background);
    padding: var(--mb-0-75);
    box-shadow: 4px 4px 45px 0 rgba(0,0,0,.11);
    margin-bottom: 8px;
    padding: 6px 12px;
}

.service_notification{
    list-style: none;
}

.service_notification li{
    padding: 8px 12px;
    background-color: var(--first-color);
    color: var(--first-color-second);
    border-radius: 20px;
    margin-bottom: 8px;
}
